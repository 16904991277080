import { RootState } from '@/store/types';
import { actions } from '@/store/actions';
import { make } from 'vuex-pathify';

const getDefaultState = (): RootState => ({
  lastEvents: [],
});

const state = getDefaultState();

export default {
  state,
  mutations: {
    ...make.mutations(state),
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    RESET_STATE(state: RootState) {
      Object.assign(state, getDefaultState());
    },
  },
  actions,
};
