import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuex from 'vuex';
import store from '@/store';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.use(Vuex);

const socket = io(process.env.VUE_APP_BACKEND_URL);
const vuexStore = new Vuex.Store(store);
Vue.use(VueSocketIOExt, socket, { store: vuexStore });

new Vue({
  router,
  render: (h) => h(App),
  vuetify,
  store: vuexStore,
}).$mount('#app');
