




import Banner from '@/components/Banner.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({ components: { Banner } })
export default class Home extends Vue {}
