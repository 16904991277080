import { ActionContext, ActionTree } from 'vuex';
import {
  DonationMessage,
  EventMessage,
  RootState,
  SpotifySongData,
} from '@/store/types';

const parseDonation = (message: DonationMessage): string => {
  return `${message.name}: ${message.amount}`;
};

const parseDonationGoal = (donationGoal: string): string => {
  // donationGoal looks something like: €494.00/€1,337.00
  // the desired format is 494.00 € / 1,337.00 €
  const [current, goal] = donationGoal
    .split('/')
    .map((str: string) => str.substring(1));

  return `Donation Goal: ${current} € / ${goal} €`;
};

const parseSong = (song: SpotifySongData): string => {
  return `"${song.item.name}" - ${song.item.artists
    .map((artists) => artists.name)
    .join(', ')}`;
};

const parseEvents = (lastEvents: EventMessage): string[] => {
  const result: string[] = [];

  for (const type in lastEvents) {
    if (!lastEvents.hasOwnProperty(type)) {
      continue;
    }
    switch (type) {
      case 'donation':
        if (!lastEvents.donation) {
          continue;
        }
        result.push(parseDonation(lastEvents.donation));
        break;
      case 'test':
        if (!lastEvents.test) {
          continue;
        }
        result.push(lastEvents.test);
        break;
      case 'donationGoal':
        if (!lastEvents.donationGoal) {
          continue;
        }
        result.push(parseDonationGoal(lastEvents.donationGoal));
        break;
      case 'song':
        if (!lastEvents.song || !lastEvents.song.isPlaying) {
          continue;
        }
        result.push(parseSong(lastEvents.song));
        break;
    }
  }
  return result;
};

export const actions: ActionTree<RootState, RootState> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  socket_lastEvents(
    { commit }: ActionContext<RootState, RootState>,
    message: string
  ) {
    const lastEvents = JSON.parse(message) as EventMessage;

    commit('SET_LAST_EVENTS', parseEvents(lastEvents));
  },
};
