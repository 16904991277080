













import { Component, Vue, Watch } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import MarqueeText from 'vue-marquee-text-component';

@Component({ components: { MarqueeText } })
export default class HelloWorld extends Vue {
  @Get('lastEvents')
  private lastEvents!: string[];

  private readonly DURATION = 12;
  private index = 0;

  private currentEvent = '';
  private watcherRan = false;

  @Watch('lastEvents')
  private lastEventsUpdated() {
    if (!this.watcherRan) {
      this.currentEvent = this.lastEvents[0];
      setInterval(this.nextEvent, this.DURATION * 1000);
      this.watcherRan = true;
    }
  }

  private nextEvent() {
    this.index = this.index + 1;
    if (this.index >= this.lastEvents.length) {
      this.index = 0;
    }
    this.currentEvent = this.lastEvents[this.index];
  }
}
